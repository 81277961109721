import { defineStore } from 'pinia'

export const useAuthStore = defineStore('auth', {
  state: () => {
    return { 
      info: {}
    }
  },
  getters: {},
  actions: {
    setInfo(options: any) {
      this.info = options.info;
    },
    reset() {
      this.info = {};
    }
  },
  persist: true
});