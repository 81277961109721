import req from './../https'

let prefix = (param: string) => {
  let pre = 'v1/notification';
  return pre + param
}

const notification = {
  subscription() {
    return req('get', prefix('/subscription'))
  },
  subscribe(params: object|null) {
    return req('post', prefix('/subscribe'), params)
  }
}

export default notification;
