import req from './../https'

let prefix = (param: string) => {
  let pre = 'v1/coach';
  return pre + param
}

const coach = {
  /**
   * 取得所有教練資料
   * @role ADMIN, ASSISTANT
   */
  list() {
    return req('get', prefix('/list'))
  },

  /**
   * 用關鍵字搜尋教練資料
   * @role ADMIN, ASSISTANT
   */
  search(params: object|null) {
    return req('get', prefix('/list/search'), params)
  },

  /**
   * 取得教練資料
   */
   findOne(uid: string) {
    return req('get', prefix(`/${uid}`))
  },

  /**
   * 更新教練資料
   * @role ADMIN
   * @params uid
   */
  updateOne(uid: string, params: object|null) {
    return req('put', prefix(`/${uid}`), params)
  }
}

export default coach;
