import req from './../https'

let prefix = (param: string) => {
  let pre = 'v1/form';
  return pre + param
}

const form = {
  /**
   * 取得所有表單
   * @role ADMIN
   * @param {str} roles (e.g. 1,2)
   */
  list(params: object|null = null) {
    return req('get', prefix('/list'), params)
  },

  /**
   * 創建表單
   * @role ADMIN
   * @param {str} name
   * @param {str} descritption
   * @param {str} note
   * @param {int-array} bind_roles
   */
  create(params: object|null) {
    return req('post', prefix(''), params)
  },

  /**
   * 取得表單資訊
   * @role ADMIN
   */
  findOne(id: string) {
    return req('get', prefix(`/${id}`))
  },

  /**
   * 更新表單資訊
   * @role ADMIN
   * @param {str} name
   * @param {str} descritption
   * @param {str} note
   * @param {int-array} bind_roles
   * @param {object-array} form_list
   */
  updateOne(id: string, params: object|null) {
    return req('put', prefix(`/${id}`), params)
  },

  /**
   * 刪除表單
   * @role ADMIN
   */
  deleteOne(id: string) {
    return req('delete', prefix(`/${id}`))
  },

  /**
   * 取得已啟用的表單
   * @role ADMIN
   */
  activedList(params: object|null) {
    return req('get', prefix('/actived/list'), params)
  },

  /**
   * 啟用表單
   * @role ADMIN
   * @param {str} user_id
   */
  activeForm(form_id: string, params: object|null) {
    return req('post', prefix(`/${form_id}/active`), params)
  },

  /**
   * 刪除啟用的表單
   * @role ADMIN
   * @param {str} user_id
   */
  inactiveForm(form_id: string, params: object|null) {
    return req('delete', prefix(`/${form_id}/inactive`), params)
  },

  /**
   * 查看啟用的表單內容
   */
  findOneActivedForm(code: string) {
    return req('get', prefix(`/actived/${code}`))
  },

  /**
   * 填寫表單
   */
  updateOneActivedFormContents(code: string, params: Array<object>) {
    return req('put', prefix(`/actived/${code}/fill`), params)
  }
}

export default form;
