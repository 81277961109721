import env from './env'
import auth from './auth'
import admin from './admin'
import user from './user'
import coach from './coach'
import assistant from './assistant'
import course from './course'
import lession from './lession'
import material from './material'
import form from './form'
import forum from './forum'
import file from './file'
import line from './line'
import multicastRecord from './multicastRecord'
import notification from './notification'

const v1 = {
  env,
  auth,
  admin,
  user,
  coach,
  assistant,
  course,
  lession,
  material,
  form,
  forum,
  file,
  line,
  multicastRecord,
  notification
}

export default v1;