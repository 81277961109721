import req from './../https'

let prefix = (param: string) => {
  let pre = 'v1/course';
  return pre + param
}

const course = {
  /**
   * 取得所有班級資料
   * @role ADMIN, ASSISTANT
   */
  list(params: object|null) {
    return req('get', prefix('/list'), params)
  },

  /**
   * 取得此 uid 所屬的課程資料
   * @params uid
   * @parmas semester
   */
  belongList(uid: string, params: object|null) {
    return req('get', prefix(`/${uid}/list`), params)
  },

  /**
   * 創建班級
   * @role ADMIN, ASSISTANT
   */
  create(params: object|null) {
    return req('post', prefix(''), params)
  },

  /**
   * 取得班級資料
   */
  findOne(courseId: string) {
    return req('get', prefix(`/${courseId}`))
  },

  /**
   * 更新班級資料
   * @role ADMIN, ASSISTANT
   */
  updateOne(courseId: string, params: object|null) {
    return req('put', prefix(`/${courseId}`), params)
  },

  /**
   * 刪除班級
   * @role ADMIN
   */
  deleteOne(courseId: string) {
    return req('delete', prefix(`/${courseId}`))
  },

  /**
   * 取得班級成員
   * @role ADMIN, ASSISTANT
   * @memberType {str} 成員類別 user|assistant|coach
   * @params course_id
   */
  findMembers(memberType: string, params: object|null) {
    return req('get', prefix(`/members/${memberType}`), params)
  },

  /**
   * 更新班級成員
   * @role ADMIN, ASSISTANT
   * @memberType {str} 成員類別 user|assistant|coach
   * @params course_id, ids[]
   */
  joinMembers(memberType: string, params: object|null) {
    return req('put', prefix(`/join/${memberType}`), params)
  },

  /**
   * 取得問卷調查列表
   * @params (option)type
   */
  surveyList(courseId: string, params: object|null) {
    return req('get', prefix(`/${courseId}/survey/list`), params)
  },

  /**
   * 新增問卷調查
   * @params type, content{}
   */
  insertOneSurvey(courseId: string, params: object|null) {
    return req('post', prefix(`/${courseId}/survey`), params)
  },

  /**
   * 更新問卷調查
   * @params id, type, content{}
   */
  updateOneSurvey(courseId: string, params: object|null) {
    return req('put', prefix(`/${courseId}/survey`), params)
  },

  /**
   * 取得班級公告
   */
  announcementList(courseId: string, params: object|null) {
    return req('get', prefix(`/${courseId}/announcement/list`), params)
  },

  /**
   * 新增班級公告
   * @params author_id, content
   */
  insertOneAnnouncement(courseId: string, params: object|null) {
    return req('post', prefix(`/${courseId}/announcement`), params)
  },

  /**
   * 取得訓練記錄
   */
  findTrainingRecords(courseId: string) {
    return req('get', prefix(`/${courseId}/training-record/list`))
  },

  /**
   * 取得即時調查列表
   */
  instantSurveyList(courseId: string) {
    return req('get', prefix(`/${courseId}/instant-survey/list`))
  },

  /**
   * 新增即時調查
   * @params name
   */
  createOneInstantSurvey(courseId: string, params: object) {
    return req('post', prefix(`/${courseId}/instant-survey`), params)
  },

  /**
   * 取得即時調查內容
   */
  findOneInstantSurvey(courseId: string, id: string) {
    return req('get', prefix(`/${courseId}/instant-survey/${id}`))
  },

  /**
   * 更新即時調查
   * @params name, is_open, is_show_stat, contents[]
   */
  updateOneInstantSurvey(courseId: string, id: string, params: object|null) {
    return req('put', prefix(`/${courseId}/instant-survey/${id}`), params)
  },

  /**
   * 取得調查回應列表
   */
  findInstantSurveyRepliedList(courseId: string, id: string) {
    return req('get', prefix(`/${courseId}/instant-survey/${id}/replied/list`))
  },
}

export default course;
