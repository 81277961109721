import req from './../https'

let prefix = (param: string) => {
  let pre = 'v1/auth';
  return pre + param
}

const auth = {
  /**
   * 創建帳號
   * @role ADMIN
   */
  register(params: object|null) {
    return req('post', prefix('/register'), params)
  },

  /**
   * 刪除帳號
   * @role ADMIN
   */
  deleteOne(params: object|null) {
    return req('delete', prefix('/delete'), params)
  },

  /**
   * 登入
   * @role ANONYMOUS
   */
  login(params: object|null) {
    return req('post', prefix('/login'), params)
  },

  /**
   * 登出
   * @role ANONYMOUS
   */
  logout() {
    return req('post', prefix('/logout'))
  },

  /**
   * 檢查 member id 是否重複
   * @role ADMIN
   */
  checkMemberId(params: object|null) {
    return req('get', prefix('/check-member-id'), params)
  },

  /**
   * 檢查登入狀況
   * @role ANONYMOUS
   */
  checkLogin() {
    return req('get', prefix('/check-login'))
  },

  /**
   * 修改密碼
   * @role ADMIN, ASSISTANT
   */
  changePassword(params: object) {
    return req('put', prefix('/change-password'), params)
  }
}

export default auth;
