import req from './../https'

let prefix = (param: string) => {
  let pre = 'v1/user';
  return pre + param
}

const user = {
  /**
   * 取得所有會員資料
   * @role ADMIN, ASSISTANT
   */
  list() {
    return req('get', prefix('/list'))
  },

  /**
   * 用關鍵字搜尋會員資料
   * @role ADMIN, ASSISTANT
   */
  search(params: object|null) {
    return req('get', prefix('/list/search'), params)
  },

  /**
   * 取得會員資料
   */
  findOne(uid: string) {
    return req('get', prefix(`/${uid}`))
  },

  /**
   * 更新會員資料
   * @role ADMIN
   * @params uid
   */
  updateOne(uid: string, params: object|null) {
    return req('put', prefix(`/${uid}`), params)
  },

  /**
   * 取得所有簽到調查紀錄
   * @params (option) course_id
   * @params (option) lession_id
   * @params (option) type
   */
   findUserRollcalls(uid: string, params: object|null) {
    return req('get', prefix(`/${uid}/rollcall/list`), params)
  },

  /**
   * 取得所有完成的作業紀錄
   * @params (option) course_id
   * @params (option) lession_id
   */
  findUserHomeworks(uid: string, params: object|null) {
    return req('get', prefix(`/${uid}/lession/homework/list`), params)
  },
  
  /**
   * 新增完成作業紀錄
   * @params course_id
   * @params lession_id
   * @params homework_id
   */
  insertOneUserHomework(uid: string, params: object|null) {
    return req('post', prefix(`/${uid}/lession/homework`), params)
  },

  /**
   * 對公告點讚
   * @params announcement_id
   */
  likeAnnouncement(uid: string, params: object) {
    return req('post', prefix(`/${uid}/announcement/like`), params)
  },

  /**
   * 收回公告的讚
   * @params announcement_id
   */
  deleteLikeAnnouncement(uid: string, params: object) {
    return req('delete', prefix(`/${uid}/announcement/like`), params)
  },

  /**
   * 取得即時調查
   */
  getInstantSurveyData(uid: string, id: string) {
    return req('get', prefix(`/${uid}/instant-survey/${id}`))
  },

  /**
   * 回覆即時調查
   * @params course_id
   * @params contents[]
   */
  replyInstantSurvey(uid: string, id: string, params: object) {
    return req('post', prefix(`/${uid}/instant-survey/${id}`), params)
  },

  /**
   * 更新回覆即時調查
   * @params contents[]
   */
  updateOneInstantSurvey(uid: string, id: string, params: object) {
    return req('put', prefix(`/${uid}/instant-survey/${id}`), params)
  },

  /**
   * 取得課程的即時調查列表
   */
  getCourseInstantSurveyList(uid: string, course_id: string) {
    return req('get', prefix(`/${uid}/instant-survey/${course_id}/list`))
  },

  /**
   * 新增客製化訓練內容
   * @param {int} training_record_id 訓練內容id
   * @param {str} course_id
   * @param {str} lession_id
   * @param {str} weight 重量
   */
  insertOneTrainingRecord(uid: string, params: object) {
    return req('post', prefix(`/${uid}/training-record`), params)
  },

  /**
   * 取得客製化訓練內容列表
   * @param {str} course_id
   * @param {str} lession_id
   */
  findTrainingRecord(uid: string, params: object) {
    return req('get', prefix(`/${uid}/training-record/list`), params)
  },

  /**
   * 刪除客製化訓練內容
   */
  deleteOneTrainingRecord(uid: string, id: string) {
    return req('delete', prefix(`/${uid}/training-record/${id}`))
  },
}

export default user;
