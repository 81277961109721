import req from './../https'

let prefix = (param: string) => {
  let pre = 'v1/material';
  return pre + param
}

const material = {
  /**
   * 取得所有教材
   * @role ASSISTANT, COACH
   */
  list(params: object) {
    return req('get', prefix('/list'), params)
  },

  /**
   * 建立教材
   * @role ASSISTANT
   */
  create(params: object|null) {
    return req('post', prefix(''), params)
  },

  /**
   * 取得資源夾資料
   * @role ASSISTANT
   */
  info(id: string) {
    return req('get', prefix(`/${id}`))
  },

  /**
   * 新增教材資源
   * @role ASSISTANT
   * @params MaterialResource
   */
  insertOneResource(id: string, params: object|null) {
    return req('post', prefix(`/${id}/resource`), params)
  },

  /**
   * 刪除教材資源
   * @role ASSISTANT
   * @params MaterialResource
   */
  deleteOneResource(id: string, resource_id: string) {
    return req('delete', prefix(`/${id}/resource/${resource_id}`))
  },
}

export default material;
