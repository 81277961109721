import req from './../https'

let prefix = (param: string) => {
  let pre = 'v1/forum';
  return pre + param
}

const forum = {
  /**
   * 取得論壇看板分類
   * @role ADMIN, ASSISTANT, COACH
   */
  categoryList() {
    return req('get', prefix('/category/list'))
  },

  /**
   * 新增看板分類
   * @role ADMIN
   */
  createCategory(params: object) {
    return req('post', prefix('/category'), params)
  },

  /**
   * 取得所有論壇文章
   * @role ADMIN, ASSISTANT, COACH
   */
  articleList() {
    return req('get', prefix('/article/list'))
  },

  /**
   * 發布文章
   * @role ADMIN, ASSISTANT, COACH
   */
  insertOneArtcile(params: object) {
    return req('post', prefix('/article'), params)
  },

  /**
   * 取得文章
   * @role ADMIN, ASSISTANT, COACH
   */
  artcileInfo(id: number) {
    return req('get', prefix(`/article/${id}`))
  },

  /**
   * 編輯文章
   * @role ADMIN, ASSISTANT, COACH
   */
  updateOneArticle(id: number, params: object) {
    return req('put', prefix(`/article/${id}`), params)
  },

  /**
   * 刪除文章
   * @role ADMIN, ASSISTANT, COACH
   */
  deleteOneArticle(id: number, params: object) {
    return req('delete', prefix(`/article/${id}`), params)
  },

  /**
   * 對文章給予反應
   * @role ADMIN, ASSISTANT, COACH
   */
  insertOneArticleReaction(id: number, params: object) {
    return req('post', prefix(`/article/${id}/reaction`), params)
  },

  /**
   * 更新對文章的反應
   * @role ADMIN, ASSISTANT, COACH
   */
  updateOneArticleReaction(id: number, params: object) {
    return req('put', prefix(`/article/${id}/reaction`), params)
  }
}

export default forum;
