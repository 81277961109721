import req from './../https'

let prefix = (param: string) => {
  let pre = 'v1/line';
  return pre + param
}

const line = {
  /**
   * LINE ID轉換為系統uid
   */
   parserLineId(params: object|null) {
    return req('get', prefix('/parse/lineId'), params)
  },
  
  /**
   * LINE綁定會員編號
   * @param member_id
   * @param line_id
   */
  userBind(params: object|null) {
    return req('post', prefix('/user/bind'), params)
  },

  /**
   * 解除LINE綁定
   * @param uid
   * @param line_id
   */
  userUnbind(params: object|null) {
    return req('post', prefix('/user/unbind'), params)
  },

  /**
   * 對班級推播LINE訊息
   * @param messages
   */
  courseMulticast(course_id: string, params: Array<object>|null) {
    return req('post', prefix(`/course/${course_id}/multicast`), params)
  }
}

export default line;
