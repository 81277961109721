import req from './../https'

let prefix = (param: string) => {
  let pre = 'v1/lession';
  return pre + param
}

const lession = {
  /**
   * 取得班級的所有課堂
   * @params course_id
   */
  list(params: object|null) {
    return req('get', prefix('/list'), params)
  },

  /**
   * 創建課堂
   * @role ADMIN, ASSISTANT, COACH
   * @params coruse_id, name, description, start_datetime
   */
  create(params: object|null) {
    return req('post', prefix(''), params)
  },

  /**
   * 取得課堂資料
   */
  findOne(lessionId: string) {
    return req('get', prefix(`/${lessionId}`))
  },

  /**
   * 更新課堂資料
   * @role ADMIN, ASSISTANT, COACH
   * @params Optional { name, description, start_datetime, equipments, homeworks[], videos[], images[] }
   */
  updateOne(lessionId: string, params: object|null) {
    return req('put', prefix(`/${lessionId}`), params)
  },

  /**
   * 刪除課堂
   * @role ADMIN, ASSISTANT, COACH
   */
  deleteOne(lessionId: string) {
    return req('delete', prefix(`/${lessionId}`))
  },

  /**
   * 延期課堂
   * @role ADMIN, ASSISTANT
   */
  postpone(courseId: string, lessionId: string, params: object) {
    return req('post', prefix(`/${courseId}/${lessionId}/postpone`), params)
  },

  /**
   * 取得課堂記錄
   */
  findNotes(courseId: string, lessionId: string) {
    return req('get', prefix(`/${courseId}/${lessionId}/note/list`))
  },

  /**
   * 創建課堂
   * @role ADMIN, ASSISTANT
   * @params content, mentionUsers[]
   */
  insertOneNote(courseId: string, lessionId: string, params: object|null) {
    return req('post', prefix(`/${courseId}/${lessionId}/note`), params)
  },

  /**
   * 取得課堂簽到紀錄
   */
  findRollcalls(courseId: string, lessionId: string, params: object|null) {
    return req('get', prefix(`/${courseId}/${lessionId}/rollcall/list`), params)
  },

  /**
   * 新增課堂簽到
   * @params user_id, type, contents[]
   */
  insertOneRollcall(courseId: string, lessionId: string, params: object|null) {
    return req('post', prefix(`/${courseId}/${lessionId}/rollcall`), params)
  },

  /**
   * 取得課堂簽到紀錄
   * @params user_id, type
   */
  findOneRollcall(courseId: string, lessionId: string, params: object|null) {
    return req('get', prefix(`/${courseId}/${lessionId}/rollcall`), params)
  },

  /**
   * 更新課堂簽到紀錄
   */
   updateOneRollcall(id: string, params: object|null) {
    return req('put', prefix(`/rollcall/${id}`), params)
  },

  /**
   * 取得訓練記錄
   */
  findTrainingRecords(courseId: string, lessionId: string) {
    return req('get', prefix(`/${courseId}/${lessionId}/training-record/list`))
  },

  /**
   * 新增訓練記錄
   * @params action_name, set, rep
   */
  insertOneTrainingRecord(courseId: string, lessionId: string, params: object) {
    return req('post', prefix(`/${courseId}/${lessionId}/training-record`), params)
  },

  /**
   * 刪除訓練記錄
   */
  deleteOneTrainingRecord(id: number) {
    return req('delete', prefix(`/training-record/${id}`))
  }
}

export default lession;
