import req from './../https'

let prefix = (param: string) => {
  let pre = 'v1/file';
  return pre + param
}

const file = {
  insertOne(params: object|null) {
    return req('post', prefix(''), params)
  },
  deleteOne(params: object|null) {
    return req('delete', prefix(''), params)
  }
}

export default file;
