import req from './../https'

let prefix = (param: string) => {
  let pre = 'v1/admin';
  return pre + param
}

const admin = {
  /**
   * 取得管理員資料
   */
  findOne(uid: string) {
    return req('get', prefix(`/${uid}`))
  },

  /**
   * 更新管理員資料
   * @role ADMIN
   * @params uid
   */
  updateOne(uid: string, params: object|null) {
    return req('put', prefix(`/${uid}`), params)
  }
}

export default admin;
