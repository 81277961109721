import req from './../https'

let prefix = (param: string) => {
  let pre = 'v1/multicast-record';
  return pre + param
}

const multicastRecord = {
  /**
   * 取得所有教材
   * @role ADMIN
   */
  list(params: object) {
    return req('get', prefix('/list'), params)
  },
  
  /**
   * 建立教材
   * @role ADMIN
   */
  insertOne(params: object|null) {
    return req('post', prefix(''), params)
  }
}

export default multicastRecord;
