import req from './../https'

let prefix = (param: string) => {
  let pre = 'v1/env';
  return pre + param
}

const env = {
  /**
   * 取得後端服務環境
   */
  getEnv() {
    return req('get', prefix(''))
  }
}

export default env;